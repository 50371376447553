import { Box, IconButton, Typography, TextField } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import React, { useState, useEffect } from 'react';
import { createCompletion, loadConversation } from '../api/Chat';
import Prompt from './Prompt';

const ChatBot = () => {
	const userLabel = "You";
	const assistantLabel = "AI Charles";
	
	const [model, setModel] = useState('gpt-3.5-turbo');
	const [prompt, setPrompt] = useState('');
	const [temperature, setTemperature] = useState(0.5);
	const [conversation_id, setConversationId] = useState("conversationId");
	const [maxTokens, setMaxTokens] = useState(100);
	const [isOpen, setIsOpen] = useState(true);
	const [userMessage, setUserMessage] = useState("");
	const [messages, setMessages] = useState([]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const newMessage = { role: 'user', content: prompt };
		setMessages((prevMessages) => [...prevMessages, newMessage]);
		const response = await createCompletion(model, [...messages, newMessage], temperature);
		setMessages((prevMessages) => [...prevMessages, { role: response.choices[0].message.role, content: response.choices[0].message.content }]);
		setPrompt('');
	};

	useEffect(() => {
		const fetchConversation = async () => {
			console.log('Loading conversation ' + conversation_id);
			
		const response = await createCompletion(model, [], temperature);
		setMessages((prevMessages) => [{ role: response.choices[0].message.role, content: response.choices[0].message.content }]);
			// const conv = await loadConversation(conversation_id);
			// setMessages(conv);
		};
		fetchConversation();
	}, [conversation_id]);


	const handleChange = (event) => {
		setUserMessage(event.target.value);
	};

	return (
		<Box
			position="fixed"
			right="1rem"
			bottom="1rem"
			bgcolor="background.paper"
			boxShadow={2}
			borderRadius={1}
			display="flex"
			flexDirection="column"
		>
			<Box display="flex" justifyContent="space-between" alignItems="center" padding="0.5rem">
				<Typography variant="subtitle1">AI Charles</Typography>
				<IconButton onClick={() => setIsOpen(!isOpen)} size="small">
					{isOpen ? <CloseIcon /> : <ChatIcon />}
				</IconButton>
			</Box>
			{isOpen && (
				<>
					<Box
						sx={{
							maxHeight: "300px",
							overflowY: "auto",
							p: 1,
							mb: 2,
							backgroundColor: (theme) =>
								theme.palette.background.paper,
							borderRadius: "4px",
							boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
							'&::-webkit-scrollbar': {
								width: '10px',
							},
							'&::-webkit-scrollbar-track': {
								backgroundColor: 'transparent',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: 'rgba(0, 0, 0, 0.2)',
								borderRadius: '20px',
							},
						}}
					>
						<div >
							{messages
								.filter((message) => message.role !== "system")
								.map((message, index) => (
									<div key={index}>
										<strong>
											{message.role === "user"
												? userLabel
												: assistantLabel}
											:
										</strong>{" "}
										{message.content}
									</div>
								))}
						</div>

					</Box>
					<Box display="flex" padding="0.5rem">
						<Prompt
							model={model}
							setModel={setModel}
							prompt={prompt}
							setPrompt={setPrompt}
							temperature={temperature}
							setTemperature={setTemperature}
							maxTokens={maxTokens}
							setMaxTokens={setMaxTokens}
							messages={messages}
							setMessages={setMessages}
							handleSubmit={handleSubmit}
						/>


						{/* <IconButton type="submit" size="small">
							<SendIcon />
						</IconButton> */}
					</Box>
				</>
			)}
		</Box>
	);
};

export default ChatBot;
