import React, { useState, useEffect } from "react";
import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import ChatBot from "./ChatBot";

const services = [
	{
		title: "Content Delivery Networks (CDN)",
		description:
			"Leverage the power of global CDNs to ensure fast and reliable content delivery. We'll help you choose and set up the most suitable CDN for your needs, optimizing your content delivery and user experience.",
	},
	{
		title: "Live Streaming Solutions",
		description:
			"Take your live events to the next level with our advanced live streaming solutions. We provide end-to-end assistance in setting up and managing your live streams, from video production to distribution and monetization.",
	},
	{
		title: "Video On Demand (VOD) Platforms",
		description:
			"Launch your own VOD platform with our help. We'll guide you through the process of platform selection, customization, and integration to provide a seamless and engaging experience for your viewers.",
	},
	{
		title: "Payment System Integration",
		description:
			"Maximize your revenue by integrating secure and efficient payment systems into your streaming services. We'll help you choose and implement the best payment gateways and subscription models to suit your business needs.",
	},
	{
		title: "Analytics & Monitoring",
		description:
			"Stay on top of your streaming performance with comprehensive analytics and monitoring. We'll help you set up and use advanced analytics tools to track user behavior, content performance, and technical metrics, ensuring the continuous improvement of your services.",
	},
	{
		title: "Custom Media Solutions",
		description:
			"Offering a variety of tailored services to meet your media management and automation needs, including video encoding/decoding, YouTube channel management, media library management, broadcast environment setup, and custom enterprise solutions.",
	},
];


const Homepage = () => {
	const [showChatBot, setShowChatBot] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowChatBot(true);
		}, 5000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<ParallaxProvider>
			<ParallaxBanner
				className="parallax-banner"
				layers={[
					{
						image: "https://example.com/your-background-image.jpg",
						amount: 0.3,
					},
				]}
				style={{
					height: "100vh",
				}}
			>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					height="100%"
				>
					{/* Hero section */}
					<Typography variant="h2" align="center" gutterBottom>
						Welcome to Dynamic Media Technologies Ltd
					</Typography>
					<Typography variant="h5" align="center">
						Your one-stop solution for streaming services and payment systems
					</Typography>
				</Box>
			</ParallaxBanner>

			{/* Services */}
			<Box padding="2rem">
				<Typography variant="h4" align="center" gutterBottom>
					Our Services
				</Typography>
				<Grid container spacing={2}>
					{services.map((service, index) => (
						<Grid item xs={12} sm={6} md={4} key={index}>
							<Card>
								<CardContent>
									<Typography variant="h6">{service.title}</Typography>
									<Typography>{service.description}</Typography>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>

			{/* Portfolio */}
			<Box padding="2rem" bgcolor="primary.main" color="white">
				<Typography variant="h4" align="center" gutterBottom>
					Portfolio
				</Typography>
				<Typography align="center">
					Check out our work on the 24-hour music television streaming station,
					360 Music Television, available on Roku.
				</Typography>
			</Box>

			{showChatBot && <ChatBot />}
		</ParallaxProvider>
	);
};

export default Homepage;
