import axios from 'axios';

const API_BASE_URL = 'https://charleskiel.dev/api';
const MODE = 'dmt'; 

export const createCompletion = async (model, messages, temperature, conversation_id) => {
	try {
		const response = await axios.post(`${API_BASE_URL}/conversation`, {
			model,
			messages,
			temperature,
			conversation_id,
			mode: MODE
		});
		console.log(response.data)
		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const loadConversation = async (conversationId) => {
	try {
		const response = await axios.get(`${API_BASE_URL}/conversation/${conversationId}`);
		console.log(response.data)
		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const loadConversations = async () => {
	try {
		const response = await axios.get(`${API_BASE_URL}/conversations`);
		console.log(response.data)
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
};
