import React, { useState } from 'react';
import { TextField, Button, Typography, Slider } from '@mui/material';
import { createCompletion } from '../api/Chat';

const Prompt = ({ model, messages = [], setMessages, handleSubmit }) => {
	const [temperature, setTemperature] = useState(0.5);
	const [prompt, setPrompt] = useState('');

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		const newMessage = { role: 'user', content: prompt };
		if (messages == null) {
			setMessages([newMessage]);
		} else {
			setMessages([...messages, newMessage]);
		}
		let updatedMessages = messages ? [...messages, newMessage] : [newMessage];

		const response = await createCompletion(model, updatedMessages, temperature);

		setMessages((prevMessages) => {
			return [...prevMessages, { role: response.choices[0].message.role, content: response.choices[0].message.content }];
		});
		setPrompt('');
	};

	return (
		<form onSubmit={handleFormSubmit}>
			<TextField
				label="Prompt"
				multiline
				fullWidth
				value={prompt}
				onChange={(event) => setPrompt(event.target.value)}
				variant="outlined"
			/>
			<div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
				{/*<Typography style={{ marginRight: '16px' }}>Temperature:</Typography>
				 <Slider
					value={temperature}
					onChange={(event, value) => setTemperature(value)}
					step={0.01}
					min={0}
					max={1}
					sx={{ width: '200px' }}
				/>
				<Typography>{temperature.toFixed(2)}</Typography> */}
			</div>
			<Button
				variant="contained"
				color="primary"
				type="submit"
				style={{ marginTop: '16px' }}
				disabled={!prompt}
			>
				Submit
			</Button>
		</form>
	);
};

export default Prompt;
